import React, { useState, useEffect } from 'react';
import LoginImage from '../resources/images/Ceremony.png'
import { FaSpinner } from 'react-icons/fa';

export default function Login(props) {
    const [name, setName] = useState(null);
    const [password, setPassword] = useState(null);
    

    const login = (e) => {
        e.preventDefault()
        props.login(name, password)
    }

    return (
        <div className="page">
            <div className="section home login">
                <form onSubmit={login}>
                    <div className="subtitle">
                        Welcome!
                    </div>
                    <img src={LoginImage} />
                    <label>Full Name</label>
                    <input type="text" onChange={(e) => setName(e.target.value)}/>
                    <label>Password</label>
                    <input type="password" onChange={(e) => setPassword(e.target.value)}/>
                    <button type="submit" className="button">{ props.spinner ? <FaSpinner icon="spinner" className="spinner"/> : "Login"  }</button>
                </form>
            </div>
        </div>
    );
}