import Menu from '../components/Menu.js'
import Home from '../components/Home.js'
import Ceremony from '../components/Ceremony.js'
import Reception from '../components/Reception.js'
import AboutUs from '../components/AboutUs.js'
import FAQ from '../components/Faq.js'

export default function Main(props) {
    return (
        <div className="page">
           <Menu logout={props.logout} reception={props.reception}/>
           <Home reception={props.reception}/>
           <Ceremony token={props.token} users={props.users}/>
           { props.reception != null && props.reception && <Reception token={props.token} users={props.users}/>}
           <AboutUs />
           <FAQ />
        </div>
    );
}