import React, { useState, useEffect } from 'react';

import Main from './pages/Main.js'
import Login from './pages/Login.js'

import Cookies from 'js-cookie';

export default function App() {
    const [token, setToken] = useState(null);
    const [reception, setReception] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [users, setUsers] = useState(null);

    const loginUser = (name, password) => {
        const LOGIN_ENDPOINT = "https://vke6iceqhguu5egt2nfd4kmkvy0cfrsb.lambda-url.ap-southeast-2.on.aws/"
        setSpinner(true)

        fetch(LOGIN_ENDPOINT, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "name": name, 
                "password": password
            })
        })
        .then(response => response.json())
        .then(response  => {
            setSpinner(false)
            var response_token = response[0]
            if ("fred-and-eunike-token" in response_token){
                const token = response_token["fred-and-eunike-token"]
                
                response.shift()

                Cookies.set("fred-and-eunike-token", token)
                setToken(token)
                setUsers(response)

                if ("reception_attendance" in response[0]){
                    setReception(true)
                }

            } else {
                alert(response["message"])
            }
        });

    }

    const logout = () => {
        Cookies.remove("fred-and-eunike-token")
        setToken(null)
        setUsers(null)
    }

  return (
    <div className="container">
      {token === null ? 
        <Login login={loginUser} spinner={spinner}/> : 
        <Main logout={logout} reception={reception} token={token} users={users}/>
      }
    </div>
  );
}
