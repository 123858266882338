import FAQImage from '../resources/images/FAQ.png'
import Divider from './Divider.js'

export default function Faq() {
    return (
        <div className="section">
            <a className="anchor" id="faq"></a>
            <div className="title"><span>FAQ</span></div>
            <div className="detail-with-image">
                <img src={FAQImage}/>
                <div className="text">
                    <div>If you have any questions which aren't covered in this FAQ, 
                        feel free to contact us instead.</div>
                    <a className="button" href="#contact-us">Contact Us</a>
                </div>
            </div>

            <a className="anchor" id="contact-us"></a>
            <div className="details contact-us">
                <div className="subtitle">Contact Details</div>
                <div>
                    Feel free to reach out to us via text, Facebook messenger, anything.<br />
                    <br />
                    You can even opt to e-mail us instead.
                </div>
                <a href="mailto:hello@fredandeunike.dev" className="button">E-mail Us</a>
            </div>

            <div className="details">
                <div className="subtitle">Dress Code</div>
                <div>
                    Cocktail attire.<br />
                    Please don't wear white or shades of white.
                </div>
            </div>

            <Divider />

            <div className="details">
                <div className="subtitle">Parking</div>
            </div>

            <Divider />

            <div className="details">
                <div className="subtitle">Gifts</div>
                <div>
                    A wishing well will be present at the event where you will be able to drop in your red pockets.
                </div>
            </div>

            <Divider />

            <div className="details">
                <div className="subtitle">Plus Ones</div>
                <div>
                    Only the people mentioned in your RSVP are invited.<br />
                    Please do not bring additional guests.
                </div>
            </div>

            <Divider />

            <div className="details">
                <div className="subtitle">Hashtag</div>
                <div>
                Guests are invited to take photos throughout the event, unless informed otherwise by the minister or the MCs. When uploading them to social media, feel free to tag it with #FredEunike2024.
                </div>
            </div>

            <Divider />

            <div className="details">
                <div className="subtitle">Tardiness</div>
                <div>
                If you are late to the ceremony, you will not be let in until after the bridal party enters to avoid any possible disruptions to the schedule.
                </div>
            </div>

            <Divider />

            <div className="details">
                <div className="subtitle">Late RSVPs</div>
                <div>
                If you do not RSVP by Jan 20, 2024, we will assume you are not coming.<br />
                Please do not rock up on the day!
                </div>
            </div>
        </div>
    );
}