export default function Home(props) {
    return (
        <div className="section home">
            <a className="anchor" id="home"></a>
            <div className="details">
                YOU ARE INVITED TO THE MARRIAGE OF
            </div>
            <div className="name-container">
                <div className="name">Fred</div>
                <div>AND</div>
                <div className="name">Eunike</div>
            </div>

            <div className="date details">
                SATURDAY 20TH APRIL, 2024
            </div>

            <div className="details">
                CEREMONY AT 9:45AM<br /><br />
                ST. ANDREW'S CATHEDRAL<br /><br />
                CNR OF GEORGE AND BATHURST ST, TOWN HALL<br /><br />
                <a className="button" href="#ceremony">
                    RSVP
                </a>
            </div>

            { props.reception != null && props.reception && 
                <div className="details">
                    RECEPTION AT 6:00PM<br /><br />
                    12-MICRON<br /><br />
                    TOWER 1, LEVEL 2/199, BARANGAROO AVE, BARANGAROO<br /><br />
                    <a className="button" href="#reception">
                        RSVP
                    </a>
                </div>
            }

            <div className="details">
                KINDLY RSVP BY 20TH JANUARY 2024
            </div>
        </div>
    );
}