import React, { useState, useEffect } from 'react';

import CeremonyImage from '../resources/images/Ceremony.png'
import Rsvp from './Rsvp.js'
import Divider from './Divider.js'

import jwt from 'jwt-decode'

export default function Ceremony(props) {
    // const [token, setToken] = useState(props.token);
    const [users, setUsers] = useState(props.users);

    // useEffect(()=>{
    //     readCookie()
    // }, [])
    
    return (
        <div className="section">
            <a className="anchor" id="ceremony"></a>
            <div className="title"><span>The Ceremony</span></div>
            <div className="detail-with-image">
                <img src={CeremonyImage}/>
                <div className="text">
                    <div>St. Andrew's Cathedral, Town Hall</div>
                    <div>Saturday, April 20th, 2024</div>
                    <div>9:45am - 12:00pm</div>
                    <div>Please RSVP by January 20th, 2024</div>
                    <div>If you have any special dietary requirements, please contact us.</div>
                    <a className="button" href="#contact-us">Contact Us</a>
                </div>
            </div>

            <div className="details rsvp-container">
                <div className="subtitle">
                    RSVP
                </div>
                <div>
                Eunike is too ceebs to fix this so please RSVP one by one lol
                </div>
                <div className="form-container">                
                { Object.keys(users).map((key, index) => ( 
                    <Rsvp users={users} data={users[key]} name={key} rsvp={users[key]} event="ceremony" />
                ))}
                </div>
            </div>

            <div className="details">
                <div className="subtitle">Getting There</div>
                <i>Church entrance is the one closest to Town Hall Square.</i><br/><br/>
                <div>
                    <u>Public Transport</u><br />
                    <br />
                    <i>Closest Lightrail Stop</i><br />
                    Town Hall Light Rail Station<br />
                    <br />
                    <i>Closest Train Station</i><br />
                    Town Hall Station, Exit 5<br />
                    <br />
                    <u>Driving</u><br />
                </div>
            </div>

            <Divider />

            <div className="details">
                <div className="subtitle">Accessibility</div>
                <div>
                    If you have any accessibility requirements apart from wheelchair access, please contact us.
                </div>
                <a className="button" href="#contact-us">Contact Us</a>
            </div>

            <Divider />

            <div className="details">
                <div className="subtitle">Refreshments</div>
                <div>
                    Light refreshments will be available after the ceremony.<br />
                    If you have any special dietary requirements, please contact us.
                </div>
                <a className="button" href="#contact-us">Contact Us</a>
            </div>
        </div>
    );
}