import React, { useState, useEffect } from 'react';
import ReceptionImage from '../resources/images/Reception.png'
import Rsvp from './Rsvp.js'
import Divider from './Divider.js'
import {Spotify} from 'react-spotify-embed';
import jwt from 'jwt-decode'

export default function Reception(props) {
    const [users, setUsers] = useState(props.users);

    const readCookie = () => {
        // console.log("here xD")
        // console.log(token)
        // try {
        //     var decoded = jwt(token)
        //     var owner = decoded["owner"]
        //     var ceremonyRsvp = decoded["rsvp_reception"]

        //     var usersDict = {}
        //     usersDict[owner] = ceremonyRsvp

            
            
        //     if ("plus_one" in decoded){
        //         var plusOne = decoded["plus_one"]["name"]
        //         var plusOneCeremonyRsvp = decoded["plus_one"]["rsvp_reception"]
        //         usersDict[plusOne] = plusOneCeremonyRsvp
        //         console.log(plusOneCeremonyRsvp)
        //     }

        //     if ("plus_two" in decoded){
        //         var plusTwo = decoded["plus_two"]["name"]
        //         var plusTwoCeremonyRsvp = decoded["plus_two"]["rsvp_reception"]
        //         usersDict[plusTwo] = plusTwoCeremonyRsvp
        //         console.log(plusTwo)
        //     }

        //     if ("plus_three" in decoded){
        //         var plusThree = decoded["plus_three"]["name"]
        //         var plusThreeCeremonyRsvp = decoded["plus_three"]["rsvp_reception"]
        //         usersDict[plusThree] = plusThreeCeremonyRsvp
        //     }

        //     setUsers(usersDict)
        // } catch {
        //     // Cookies.remove("fred-and-eunike-token")
        // }
        
    }

    useEffect(()=>{
        // readCookie()
    }, [])

    return (
        <div className="section">
            <a className="anchor" id="reception"></a>
            <div className="title"><span>The Reception</span></div>
            <div className="detail-with-image">
                <img src={ReceptionImage}/>
                <div className="text">
                    <div>12-Micron</div>
                    <div>Saturday, April 20th 2024</div>
                    <div>6:00pm - 11:00pm</div>
                    <div>Please RSVP by January 20th, 2024</div>
                    <div>If you have any special dietary requirements, please contact us.</div>
                    <a className="button" href="#contact-us">Contact Us</a>
                </div>
            </div>

            <div className="details">
                { Object.keys(users).map((key, index) => ( 
                    <Rsvp data={users[key]} name={key} rsvp={users[key]} event="reception" />
                ))
                }
            </div>
            
            <div className="details">
                <div className="subtitle">Getting There</div>
                <div>
                    <u>Public Transport</u><br />
                    <br />
                    <i>Closest Lightrail Stop</i><br />
                    Wynyard Light Rail Stop<br />
                    <br />
                    <i>Closest Train Station</i><br />
                    Wynyard Station, Exit 4<br />
                    <br />
                    <u>Driving</u><br />
                </div>
            </div>

            <Divider />

            <div className="details">
                <div className="subtitle">Accessibility</div>
                <div>
                    If you have any accessibility requirements apart from wheelchair access, please reach out to us.
                </div>
                <a className="button" href="#contact-us">Contact Us</a>
            </div>

            <Divider />

            <div className="details">
                <div className="subtitle">Dietary Requirements</div>
                <div>
                    If you have any special dietary requirements, please contact us.
                </div>
                <a className="button" href="#contact-us">Contact Us</a>
            </div>

            <Divider />

            <div className="details">
                <div className="subtitle">Song Requests</div>
                <div className="song-request">
                    Feel free to add in your song requests onto the <a href="https://open.spotify.com/playlist/4DSOgZ03ux2ZSE9LZAgwKX">Spotify playlist</a> and we will pass it onto the DJ.
                </div>
                <div className="spotify-container">
                    <Spotify link="https://open.spotify.com/playlist/4DSOgZ03ux2ZSE9LZAgwKX" />
                </div>
            </div>

        </div>
    );
}