import Divider from './Divider.js'

import AboutUsImage from '../resources/images/AboutUs.png'

export default function AboutUs() {
    return (
        <div className="section">
            <a className="anchor" id="about-us"></a>
            <div className="title"><span>About Us</span></div>
            <div className="detail-with-image">
                <img src={AboutUsImage}/>
                <div className="text">
                    <div>Here are some fun facts about us.</div>
                </div>
            </div>

            <div className="details">
                <div className="subtitle">Favourite boba drink.</div>
                <div>Fred - Machi Machi Grapefruit Green Tea Slush</div>
                <div>Eunike - Coco Passionfruit Black Tea with Coconut Jelly</div>
            </div>

            <div className="details">
                <div className="subtitle">Least favourite chore.</div>
                <div>Fred - The Bathroom™</div>
                <div>Eunike - Washing the dishes</div>
            </div>

            <div className="details">
                <div className="subtitle">Thing we can't live without.</div>
                <div>Fred - YouTube</div>
                <div>Eunike - Seated toilets</div>
            </div>

            <div className="details">
                <div className="subtitle">Favourite memory.</div>
                <div>Fred - Eunike eating bubblegum icecream at Kiama (why would a 25 year old eat bubblegum flavour).</div>
                <div>Eunike - Fred shucking oysters for me</div>
            </div>
        </div>
    );
}