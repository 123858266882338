import { FormGroup, FormControlLabel, Switch } from '@mui/material'
import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { FaSpinner } from 'react-icons/fa';


export default function Rsvp(props) {
    const [spinner, setSpinner] = useState(false);
    const [data] = useState(props.data)
    const [name] = useState(data["name"])
    const [rsvp, setRsvp] = useState(data[props.event + "_attendance"]);

    const toggleRsvp = () => {
        setRsvp(!rsvp)
    }

    const sendRsvp = (event) => {
        event.preventDefault();
        setSpinner(true)
        
        const RSVP_ENDPOINT = "https://sool4obbzeafksemxtzanc72lu0ycnmv.lambda-url.ap-southeast-2.on.aws/"

        fetch(RSVP_ENDPOINT, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'fred-and-eunike-token': Cookies.get('fred-and-eunike-token')

            },
            body: JSON.stringify({
                "name": name,
                "event": props.event,
                "rsvp status": rsvp
            })
        })
        .then(r =>  r.json()
        .then(data => ({status: r.status, body: data, headers: r.headers})))
        .then(response => {
            setSpinner(false)
            if (response["status"] == 403){
                alert(response["body"])
                props.logout()
            } else if (response["status"] == 200) {
                alert("Thank you for your submission!")
            }
        });
    }

    return (
        <form onSubmit={e => sendRsvp(e)}>
            <div>{name}</div>
            <FormGroup>
                <FormControlLabel control={
                    <Switch
                        defaultValue={rsvp}
                        checked={rsvp}
                        onChange={toggleRsvp}
                    />
                } label={rsvp ? `Attending` : `Not Attending`} />
            </FormGroup>
            
            <button className="button">{ spinner ? <FaSpinner icon="spinner" className="spinner"/> : "Submit"  }</button>
        </form>
    );
}