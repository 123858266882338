import { IoLogOutOutline } from 'react-icons/io5'

export default function Menu(props) {
    return (
        <div className="menu">
                <a href="#home">Home</a>
                <a href="#ceremony">Ceremony</a>
                { props.reception != null && props.reception && <a href="#reception">Reception</a> }
                <a href="#about-us">About Us</a>
                <a href="#faq">FAQ</a>
                <div onClick={props.logout}><IoLogOutOutline /></div>
        </div>
    );
}